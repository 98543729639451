import React, { useEffect, useState } from "react";
import sanitizeHtml from "sanitize-html";
import * as Card from "../../components/Styled/Card";
import { colors } from "../../components/Styled/Colors";
import {
  Full,
  Half, Row
} from "../../components/Styled/Grid.js";
import { ProductPrice } from "../Shopify/ProductComponents";
import BuyButton from "../Styled/BuyButton";


// import { withRouter, useLocation } from "react-router-dom";


const AddToCart = ({ price, addVariantToCart, id, variantQuantity , trackingObject, discounted, compareAtPrice}) => (
  <Row>
    <Half fit>
      <ProductPrice margin={"none"} strikeThrough={discounted}>£{price.amount}</ProductPrice>
            {discounted &&  <ProductPrice color={colors.purple} margin={"none"}>£{compareAtPrice}</ProductPrice>}
    </Half>
    <Half>
      <BuyButton onClick={() => addVariantToCart(id, variantQuantity, trackingObject)}>
        Add to Cart
      </BuyButton>
    </Half>
  </Row>
);

const ComingSoon = () => (
  <Row>
    <Full>
      <Card.Category> Coming soon </Card.Category>
    </Full>
  </Row>
);

const Content = ({ text }) => {
  let clean = sanitizeHtml(text, {
    allowedTags: ["b", "i", "em", "strong", "a"]
  });
  return (
    <span
      className="product-description"
      dangerouslySetInnerHTML={{
        __html: clean
      }}
    />
  );
};


// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }


const CardComponent = ({
  variantImage,
  title,
  images,
  type,
  text,
  compareAtPrice,
  price,
  small,
  id,
  ...rest
}) => {

    // let query = useQuery();
  const [discounted, setDiscounted] = useState(false);

  useEffect(() => {
    

    // let vip = query.get("vip");
    // if(vip === "earlyaccess"){
    //   // setDiscounted(true);
    // }

  },[])

  const trackingObject = {
    // content_ids: [atob(id).replace('gid://shopify/ProductVariant/', '')],
    // content_type: "product",
    // currency: 'GBP',
    // value: price
  };

  return (
    <Card.Container product={true}>
      <Card.ContainerInner>
      {<Card.Image jot={false} img={variantImage.src} />}
      {/* <ProductImage images={images} variantImage={variantImage} alt={title} /> */}
      <Card.Body>
        {type && <Card.Category> {type} </Card.Category>}
        {title && <Card.Title small={small}> {title} </Card.Title>}
        {text && (
          <Card.Text>
            <Content text={text} />
          </Card.Text>
        )}
        {price ? <AddToCart discounted={discounted} trackingObject={trackingObject} compareAtPrice={compareAtPrice} price={price} id={id} {...rest} /> : <ComingSoon />}
      </Card.Body>
      </Card.ContainerInner>

    </Card.Container>
  );
};

export default CardComponent;
