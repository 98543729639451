import React from "react";
import styled from "styled-components/macro";
import { device } from "../Styled/Breakpoints";
import VariantSelector from "./VariantSelector";


export const Features = styled.ul`
   padding: 10px 0 0 20px;
`;
export const Feature = styled.li`
  ${props => props.theme.setFontWithRhythm(props.theme.font.p.family, props.small ? props.theme.font.p.smallSize :props.theme.font.p.size, props.theme.font.p.lineHeight)}
`;

export const Specifications = styled.ul`
   padding: 10px 0 0 20px;
`;

export const Spec = styled.li`
  ${props => props.theme.setFontWithRhythm(props.theme.font.p.family, props.small ? props.theme.font.p.smallSize :props.theme.font.p.size, props.theme.font.p.lineHeight)}
`;

export const ProductTitle = styled.h1`
font-size: ${props => props.theme.font.h1.size};
font-family: ${props => props.theme.font.h1.family};
line-height: ${props => props.theme.font.h1.lineHeight};
margin-top: ${props => props.theme.spacing.l}px;
margin-bottom: ${props => props.theme.spacing.l}px;
`;


export const ColourOptionContainer = styled.div`
  display: block;
  overflow: hidden;
  padding: 3px;
`

export const ColourOption = styled.div`
  flex-direction: row;
  background-color: #f80;
  border: 3px solid #fff;
  border-radius: 18px;
  float: left;
  margin-right: 20px;
  height: 30px;
  width: 30px;
  transition: all 0.5s;
  


.circle1_container{
  display: inline-block;
  position: absolute;
  top: 100px;
  margin-left: 120px;

  .circle1{
    background: #1b91f5;
    border-top-left-radius: $size * 2;
    border-top-right-radius: $size * 2;
    height: $size;
    width: $size * 2;
    transform: rotate(60deg);
  }
  
  @media only screen and (orientation : portrait) {
    top: 50px;
  }

  @include media-breakpoint-down(md) {  
    display: none 
  }
}

.circle2_container{
  .circle2{
    height: 100px;
    width: 100px;
    background: $second;
    border-radius: 50%;
  }

  position: absolute;
  margin-left: -70px;
  top: 300px;
  @include media-breakpoint-down(md) {  display: none }

}

.circle3_container{
  .circle3{
    height: 110px;
    width: 110px;
    background: $fourth;
    border-radius: 50%;
  }
  position: absolute;
  margin-left: 30px;
  z-index: 1000;
  top: 550px;
  @include media-breakpoint-down(md) {  display: none }

}

  &.blue {
    background: #1b91f5 !important;
    &.active {
      box-shadow: 0 0 0 2px #1b91f5 !important;
    }
    //outline: 2px solid #1b91f5 !important;
  }

  &.slate {
    background: #666;
    &.active {
      box-shadow: 0 0 0 2px #666;
    }
    //outline: 2px solid #333;
  }

  &.black {
    background: #000;
    &.active {
      box-shadow: 0 0 0 2px #000;
    }
    //outline: 2px solid #000;
  }

  &.white {
    background: white;
    border: 1px solid #e3e3e3;
    &.active {
      box-shadow: 0 0 0 2px #000;
    }
    //outline: 2px solid #000;
  }
`

export const OptionTitle = styled.h4`
  display: block;

`;

export const StyledProductOptions = styled.div`
  width: 100%;
  color: black !important;
      padding-top: ${props=>props.theme.spacing.xs}rem;
      padding-bottom: ${props=>props.theme.spacing.xs}rem;
  &:first-of-type {
    /* border-top: 1px solid black; */
  }

  & + .product-options {
    padding: 0;
  }

  select{
    width: 200px;
  }
`; 

export const ProductDescription = styled.div`
  span{
    p{  
      ${props => props.theme.setFontWithRhythm(props.theme.font.p.family, props.small ? props.theme.font.p.smallSize :props.theme.font.p.size, props.theme.font.p.lineHeight)}
      margin-top: ${props=>props.theme.spacing.s}rem;
      margin-bottom: ${props=>props.theme.spacing.s}rem;
      font-family: "Neutrif Studio";
    }
  }
`;
export const ProductPrice = styled.h2`
    font-family: "Neutrif Studio";
    margin-top: ${props => props.margin ? props.margin : props.theme.spacing.s*0.7}rem;
    font-size: 2rem;
    text-decoration: ${props => props.strikeThrough ? "line-through" : "none"};
      color: ${props => props.color ? props.color : "black"};

    @media ${device.laptopS} {
      font-size: 2rem;
    }

    @media ${device.laptop} {
      font-size: 1.5rem;
    }

`;

export const ProductImage = ({ images = {}, variantImage = {}, alt }) => {
  const { src } = images[0];
  return images.length && src ? (
    <img src={src} alt={`${alt} product shot`} />
  ) : null;
};

export const VariantSelectors = ({
  options,
  selectedOptions,
  handleOptionChange
}) =>
  options.map(option => {
    return (
      <VariantSelector
        selectedOptions={selectedOptions}
        handleOptionChange={handleOptionChange}
        key={option.id.toString()}
        option={option}
      />
    );
  });

export const ProductOptions = ({ variantQuantity, handleQuantityChange }) => {
  return (
    <label className="Product__option">
      Quantity
      <input
        min="1"
        type="number"
        defaultValue={variantQuantity}
        onChange={handleQuantityChange}
      />
    </label>
  );
};

export const BuyButton = ({ addVariantToCart, id, variantQuantity }) => {
  return (
    <button
      className="Product__buy button"
      onClick={() => addVariantToCart(id, variantQuantity)}
    >
      Add to Cart
    </button>
  );
};
