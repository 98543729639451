import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Black from '../../assets/images/Black.png'
import Blue from '../../assets/images/Blue.png'
import shapes from '../../assets/images/shapes.png'
import Slate from '../../assets/images/Slate.png'
import White from '../../assets/images/White.png'
import {
  ProductPrice,
  Spec,
  Specifications,
  VariantSelectors,
} from '../../components/Shopify/ProductComponents'
import { colors } from '../../components/Styled/Colors'
import { Col, Half, Row } from '../../components/Styled/Grid.js'
import { Body, Title } from '../../components/Styled/Type.js'
import { addVariantToCart, client } from '../../State/cart/operations'
import BuyButton from '../Styled/BuyButton'
import { Button } from '../../components/Styled/Components'

const jotoImages = { Blue: Blue, White: White, Slate: Slate, Black: Black }

const ModalExample = props => {
  const { buttonLabel, className } = props

  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  return (
    <>
      <a
        color="danger"
        onClick={toggle}
        style={{ color: colors.purple, cursor: 'pointer', paddingTop: 20 }}
      >
        View specifications
      </a>
      <Modal centered isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Specifications</ModalHeader>
        <ModalBody>
          <Specifications title={'Specs'}>
            <Spec>
              <strong>Dimensions</strong> - 393mm x 472mm x 88mm
            </Spec>
            <Spec>
              <strong>Weight</strong> - 3 kg
            </Spec>
            <Spec>
              <strong>Ink Color</strong> - Black
            </Spec>
            <Spec>
              <strong>Wireless</strong> - 2.4GHz 802.11 b/g/n
            </Spec>
            <Spec>
              <strong>Inputs</strong> - Wifi Connection / USB-B Micro Serial
            </Spec>
            <Spec>
              <strong>Power</strong> - 63W USB-C
            </Spec>
          </Specifications>
        </ModalBody>
      </Modal>
    </>
  )
}

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const LeadDevice = ({
  product: { title, descriptionHtml, images = [], options, variants = [] },
  product,
}) => {
  // let query = useQuery();

  const [trackingObject, setTrackingObject] = useState({})
  const [discounted, setDiscounted] = useState(false)
  const [state, setState] = useState(jotoImages['Blue'])

  const dispatch = useDispatch()
  const [selectedVariant, setSelectedVariant] = useState(variants[0])
  const [selectedVariantImage, setSelectedVariantImage] = useState(images[0])
  const [selectedVariantQuantity, setSelectedVariantQuantity] = useState(1)
  const [selectedOptions, setSelectedOptions] = useState(() => {
    let defaultOptionValues = {}
    options.forEach(
      selector =>
        (defaultOptionValues[selector.name] = selector.values[0].value)
    )
    return defaultOptionValues
  })

  const handleOptionChange = (event, type, value) => {
    let newOptions
    if (type === 'color') {
      newOptions = { ...selectedOptions, Color: value }
      selectJoto(value)
      setSelectedOptions(newOptions)
    } else {
      const { target } = event
      newOptions = { ...selectedOptions, [target.name]: event.target.value }
      setSelectedOptions(newOptions)
    }
    let newSelectedVariant = client.product.helpers.variantForOptions(
      product,
      newOptions
    )
    setSelectedVariant(newSelectedVariant)
  }

  const addToCart = (id, variantQuantity, trackingObject) => {
    dispatch(addVariantToCart(id, variantQuantity, trackingObject))
  }

  useEffect(() => {

    console.log("selectedVariant", selectedVariant);
    // const cookies = new Cookies();
    // let vip = query.get("vip");
    // if (vip === "earlyaccess") {
    //   cookies.set("vip", true, { path: "/" });
    //   setDiscounted(true);
    // } else {
    //   let vip = cookies.get("vip");
    //   setDiscounted(vip === "true");
    // }
    setDiscounted(true)

    // setTrackingObject({
    //   content_ids: [
    //     atob(selectedVariant.id).replace('gid://shopify/ProductVariant/', ''),
    //   ],
    //   content_type: 'product',
    //   currency: 'GBP',
    //   value: selectedVariant.price,
    // })
  }, [selectedVariant])

  const selectJoto = value => {
    setState(jotoImages[value])
  }

  return (
    <Row switch>
      <Half middle right>
        <Title color={colors.purple}>{'Order Your Joto'}</Title>

        {/* <span dangerouslySetInnerHTML={{ __html: descriptionHtml }} /> */}
        <span>
          {
            "You'll get everything you need in the box to get up and running including ink, nibs, erasers, a USB-C power adapter and mounting screws."
          }
        </span>
        <ModalExample />

        <VariantSelectors
          selectedOptions={selectedOptions}
          options={options}
          handleOptionChange={handleOptionChange}
        />

        {/* <ProductPrice margin={'none'} strikeThrough={discounted}>
          £{selectedVariant.compareAtPrice.amount}
        </ProductPrice> */}
        {discounted && (
          <ProductPrice color={colors.purple} margin={'none'}>
            £{selectedVariant.price.amount}
          </ProductPrice>
        )}

        <Button
        color={colors.purple}
          disabled={!selectedVariant.available}
          onClick={() =>
            addToCart(
              selectedVariant.id,
              selectedVariantQuantity,
              trackingObject
            )
          }
        >
          Buy Now
        </Button>
      </Half>
      <Half>
        {/* <ProductImage
          images={images}
          variantImage={selectedVariantImage}
          alt={title}
        /> */}
        <img
          src={shapes}
          style={{ zIndex: -1, position: 'absolute', top: 0, left: -50 }}
        />

        <img src={state} />
        <Body style={{ position: 'absolute', bottom: 0, right: 0 }}>
          Illustration by @quibe
        </Body>
      </Half>
    </Row>
  )
}

export default LeadDevice
