import React from "react";
import {
  ColourOption, ColourOptionContainer, OptionTitle, StyledProductOptions
} from "../Shopify/ProductComponents";

const VariantSelector = ({ option, handleOptionChange, selectedOptions }) => {
  return option.name === "Title" ? null : option.name === "Color" ? (
    <StyledProductOptions>
      <OptionTitle>Choose your case colour</OptionTitle>
      <ColourOptionContainer>
        {option.values.map((value, key) => {
          let activeClass = selectedOptions
            ? selectedOptions.Color === value.value
              ? "active"
              : ""
            : null;
          return (
            <ColourOption
              key={key}
              className={`color_option ${value.value.toLowerCase()} ${activeClass}`}
              onClick={event => handleOptionChange(event, "color", value.value)}
            />
          );
        })}
      </ColourOptionContainer>
    </StyledProductOptions>
  ) : (
    <StyledProductOptions>
      <OptionTitle>Power adapter</OptionTitle>
      <select
        style={{width: "100%", maxWidth: "350px"}}
        className="Product__option"
        name={option.name}
        key={option.name}
        onChange={handleOptionChange}
      >
        {option.values.map((value, key) => {
          return (
            <option value={value.value} key={key}>{`${value.value}`}</option>
          );
        })}
      </select>
    </StyledProductOptions>
  );
};

export default VariantSelector;
