import React, { useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import Addon from "../components/Elements/Addon";
import Device from "../components/Elements/Device";
import Consumable from "../components/Shopify/Consumable";
import { Container, Row } from "../components/Styled/Grid.js";
import Loader from "../components/Styled/Loader";
import { theme } from "../styles/theme";

const ProductLoop = ({ type, products, loading, client }) => {
  const [discounted, setDiscounted] = useState(false);

  useEffect(() => {
    setDiscounted(false);

    // const cookies = new Cookies();
    // setDiscounted(cookies.get("vip"));
  }, []);

  if (loading) {
    return (
      <Loader>
        <PulseLoader
          sizeUnit={"px"}
          size={5}
          color={"#000000"}
          loading={loading}
        />
      </Loader>
    );
  } else {
    switch (type) {
      case "addons":
        return (
          <Container color={theme.color.backgroundShade}>
            <Row className="">
              {products.map(product => {
                return (
                  <Addon
                    discounted={discounted}
                    key={product.id.toString()}
                    product={product}
                  />
                );
              })}
            </Row>
          </Container>
        );
      case "consumables":
        return (
          <Container color={theme.color.backgroundShade}>
            <Row className="">
              {products.map(product => (
                <Consumable
                  discounted={discounted}
                  key={product.id.toString()}
                  product={product}
                />
              ))}
            </Row>
          </Container>
        );
      case "devices":
        return (
          <Container switch fluid>
            <Row className="">
              {products.map(product => {
                console.log("product", product);

                return (
                  <Device
                    discounted={discounted}
                    client={client}
                    key={product.id.toString()}
                    product={product}
                  />
                );
              })}
            </Row>
          </Container>
        );
      default:
        return null;
    }
  }
};

export default ProductLoop;
