import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Third
} from "../../components/Styled/Grid.js";
import { addVariantToCart } from "../../State/cart/operations";
import Card from "../Shopify/ProductCard";


const ProductConsumable = ({
  product,
  selectedVariantQuantity,
  selectedVariant,
  selectedVariantImage,
  discounted
}) => {
  const dispatch = useDispatch();
  const [trackingObject, setTrackingObject] = useState({});

  let {
    title,
    productType,
    descriptionHtml,
    images = [],
    variants = []
  } = product;
  let variantImage = selectedVariantImage || images[0];
  let variant = selectedVariant || variants[0];
  let variantQuantity = selectedVariantQuantity || 1;
  let { id, price, compareAtPrice } = variant;

  useEffect(() => {
    setTrackingObject({
      // content_ids: [
      //   atob(variant.id).replace("gid://shopify/ProductVariant/", "")
      // ],
      // content_type: "product",
      // currency: "GBP",
      // value: variant.price
    });
  }, [variant]);

  const addToCart = (id, variantQuantity, trackingObject) => {
    dispatch(addVariantToCart(id, variantQuantity, trackingObject));
  };

  return (
    <Third gutter>
      <Card
        title={title}
        variantImage={variantImage}
        text={descriptionHtml}
        price={price}
        type={"Essentials"}
        images={images}
        addVariantToCart={() =>
          addToCart(variant.id, variantQuantity, trackingObject)
        }
        id={id}
        compareAtPrice={compareAtPrice}
        variantQuantity={variantQuantity}
        discounted={discounted}
      />
    </Third>
  );
};

export default ProductConsumable;
