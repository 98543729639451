import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Half } from "../../components/Styled/Grid.js";
import Card from "../Shopify/ProductCard";
import { addVariantToCart } from "../../State/cart/operations";

const ProductAddon = ({ product, selectedVariant, selectedVariantImage, discounted }) => {
  const dispatch = useDispatch();
  const [trackingObject, setTrackingObject] = useState({});

  let {
    title,
    productType,
    descriptionHtml,
    images = [],
    variants = []
  } = product;
  let variantImage = images[0];
  let variant = variants[0];
  let variantQuantity = 1;
  let { price, id, compareAtPrice } = variant;

  useEffect(() => {

    console.log("variant", variant);
    setTrackingObject({
      // content_ids: [
      //   atob(variant.id).replace("gid://shopify/ProductVariant/", "")
      // ],
      // content_type: "product",
      // currency: "GBP",
      // value: variant.price.amount
    });
  }, [variant]);

  const addToCart = (id, variantQuantity, trackingObject) => {
    dispatch(addVariantToCart(id, variantQuantity, trackingObject));
  };

  return (
    <Half gutter>
      <Card
        discounted={discounted}
        title={title}
        variantImage={variantImage}
        text={descriptionHtml}
        price={price}
        type={productType}
        images={images}
        addVariantToCart={() =>
          addToCart(variant.id, variantQuantity, trackingObject)
        }
        compareAtPrice={compareAtPrice}
        id={id}
        variantQuantity={1}
      />
    </Half>
  );
};

export default ProductAddon;
